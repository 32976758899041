<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- :class="selected.length == 0 ? 'inactive' : ''" -->
<template>
  <div>
    <v-col cols="12" md="12">
      <v-row>
        <v-col md="6" cols="12">
          <h3 class="cart-title mb-5">{{ $t("Student") }}</h3>
        </v-col>

        <v-col md="6" cols="12" class="text-right">
          <v-btn
            :disabled="isAddButtonDisabled"
            small
            style="
              margin-top: 20px !important;
              margin-bottom: 0px !important;
              display: inline-block;
              margin-right: 0.5rem;
            "
            @click="reverseSelectedStudent"
          >
            <svg
              style="padding-bottom: 0.5rem !important"
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
            >
              <mask
                id="mask0_728_6107"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="1"
                y="6"
                width="37"
                height="27"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.875 13L24.375 19.5H29.25C29.25 24.8787 24.8787 29.25 19.5 29.25C17.8587 29.25 16.2987 28.8438 14.95 28.1125L12.5775 30.485C14.5762 31.7525 16.9487 32.5 19.5 32.5C26.6825 32.5 32.5 26.6825 32.5 19.5H37.375L30.875 13ZM9.75 19.5C9.75 14.1213 14.1213 9.75 19.5 9.75C21.1412 9.75 22.7013 10.1563 24.05 10.8875L26.4225 8.515C24.4238 7.2475 22.0513 6.5 19.5 6.5C12.3175 6.5 6.5 12.3175 6.5 19.5H1.625L8.125 26L14.625 19.5H9.75Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_728_6107)">
                <g filter="url(#filter0_d_728_6107)">
                  <rect
                    v-if="isAddButtonDisabled"
                    width="39"
                    height="39"
                    fill="#FFFFFF"
                  />
                  <rect
                    v-if="!isAddButtonDisabled"
                    width="39"
                    height="39"
                    fill="#5A86FF"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_728_6107"
                  x="-4"
                  y="0"
                  width="47"
                  height="47"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_728_6107"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </v-btn>
          <!-- ------------------------------------------ -->
          <v-btn
            :disabled="isAddButtonDisabled"
            small
            style="
              background-color: #5a86ff;
              margin-top: 20px !important;
              margin-bottom: 0px !important;
              display: inline-block;
            "
            @click="getSelectedStudents"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_52_2217)">
                <path d="M22 8L18 4V7H3V9H18V12L22 8Z" fill="white" />
                <path d="M2 16L6 20V17H21V15H6V12L2 16Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_52_2217">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <template>
            <v-row justify="center">
              <v-col class="text-right">
                <!-- ------------REFUND REQUEST-------------------- -->

                <!-- ------------REFUND REQUEST-------------------- -->
              </v-col>
            </v-row>
          </template>
          <!-- -------------------------------------Dialog------------- -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <template>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="students"
              :single-select="selectingType"
              item-key="name"
              show-select
              class="elevation-1"
              hide-default-footer
            >
              <!-- ------------------roter link ------------------ -->

              <template v-slot:[`item.withdrawal`]="{ item }">
                <router-link :to="'/OrdersHistory/' + [auth_id, item.id]">
                  {{ item.withdrawal }}
                </router-link>
              </template>
              <!-- -----------------End of router link ------------------ -->
            </v-data-table>
          </template>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snack_color"
        rounded="pill"
        top
      >
        {{ message }}
      </v-snackbar>
    </v-col>

    <v-dialog v-model="dialog" persistent max-width="350px">
      <!-- TransFer for children -->
      <v-card>
        <v-card-title>
          <span>
            <h1 class="form-title">
              {{ $t(formTitle) }}
            </h1>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="modalForm">
              <v-row v-for="(student, index) in amountTransfer" :key="index">
                <v-col cols="12" sm="12" md="6" style="padding-top: 25px">
                  <label>{{ $t(student.name) }} </label>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-row>
                    <v-text-field
                      label=""
                      style="width: 10px !important"
                      class="amountTransfer"
                      v-model="student.amount"
                      :rules="[
                        validationRules.required,
                        validationRules.onlyIntegers,
                      ]"
                      @keyup="calculateRemaining"
                    ></v-text-field>
                    <span class="currency">pts</span>
                  </v-row>
                </v-col>
              </v-row>
              <br />
              <hr />
            </v-form>
            <v-row style="padding-top: 20px">
              <v-col cols="6" sm="6" md="6">
                <label style="color: #707070 !important"
                  >{{ $t("Remaining") }}
                </label>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <label>{{ remaining }} </label>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-bottom: 30px">
          <v-spacer></v-spacer>
          <v-btn
            style="text-transform: capitalize"
            class="modal-btn-cancel"
            @click="close"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            style="text-transform: capitalize"
            class="modal-btn-save"
            @click="transfer"
            :disabled="!valid || remaining == 0"
            >{{ $t("Confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------Confirmation message------------------------- -->
    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to reverse the balnce?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="reverseConfirmation">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- -------------------Confirmation message------------------------- -->
  </div>
</template>
<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  name: "Children",
  props: ["students", "currentBalance", "currentPoints"],
  mixins: [validationMixin],
  data() {
    return {
      formTitle: "Transfer",
      selectingType: false,
      selected: [],
      dialog: false,
      confirm_dialog: false,
      valid: false,
      snackbar: false,
      timeout: 4000,
      snack_color: "success",
      message: "",
      amountTransfer: [],
      // amountReverse: [],
      studentsID: "",
      auth_id: localStorage.getItem("auth_id"),
      defaultItem: {
        id: "",
        name: "",
        amount: 0,
      },
      isSelected: "",
      headers: [
        { text: "Name", align: "center", value: "name", sortable: false },
        {
          text: "Withdrawal",
          align: "center",
          value: "withdrawal",
          sortable: false,
        },
        {
          text: "Balance",
          align: "center",
          value: "child_balance",
          sortable: false,
        },
        {
          text: "Points",
          align: "center",
          value: "child_points",
          sortable: false,
        },
      ],
      remaining: 0,
    };
  },
  methods: {
    calculateRemaining() {
      let sum = 0;
      this.amountTransfer.forEach((item) => {
        if (typeof parseInt(item.amount) === "number" && item.amount != "") {
          sum = parseInt(sum) + parseInt(item.amount);
        }
      });
      this.remaining =
        this.currentPoints - sum >= 0 ? this.currentPoints - sum : 0;
    },

    //1st
    getSelectedStudents() {
      console.log("studethis.students in transfer = ", this.students);
      var index = 0;
      console.log("typeof(sealected) = ", typeof this.selected);
      this.selected.forEach((item) => {
        this.amountTransfer[index] = {
          id: item.id,
          name: item.name,
          amount: 0,
        };
        index++;
      });
      this.calculateRemaining();
      this.dialog = true;
    },

    //2nd
    reverseSelectedStudent() {
      console.log("hello from reverse buttono");
      console.log("this.selected = ", this.selected);
      this.studentsID = this.selected[0].id;
      for (let index = 1; index < +this.selected.length; index++) {
        if (this.selected[index]) {
          this.studentsID += "," + this.selected[index].id;
        }
      }
      console.log("studentsID = ", this.studentsID);
      this.confirm_dialog = true;
    },

    reverseConfirmation() {
      console.log("i am in reverseConfirmation button");
      // this.studentsID = 19;
      HttpService.get(
        "wallet/reverseRequest/" + this.studentsID,
        "",
        true
      ).then((response) => {
        console.log(response);
        if (response.data.status.error == false) {
          this.studentsID = "";
          this.dialog = false;
          this.message = "Amount reversed to parent successfully";
          this.snack_color = "success";
          this.snackbar = true;
          window.location.reload();
        } else {
          this.snack_color = "error";
          this.snackbar = true;
        }
      });
    },

    close() {
      console.log("Iam in Close function ");
      this.amountTransfer = [];
      this.$refs.modalForm.resetValidation();
      this.selected = [];
      this.dialog = false;
    },

    transfer() {
      if (this.valid) {
        // send the amount to backend
        setTimeout(() => {
          HttpService.post(
            "wallet/transfer",
            { data: this.amountTransfer },
            true
          ).then((response) => {
            // this.message = response.data.status.message;
            if (response.data.status.error == false) {
              this.message = "Amount transferred successfully";
              this.snack_color = "success";
              this.snackbar = true;
              // setTimeout(() => {
              this.$emit("reload");
              this.close();
            } else {
              this.snack_color = "error";
              this.snackbar = true;
            }
          }, 500);
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },
    // },
  },

  computed: {
    isAddButtonDisabled() {
      // eslint-disable-next-line no-constant-condition
      return true ? this.selected.length <= 0 : false;
    },
    colorOf() {
      // eslint-disable-next-line no-constant-condition
      return "white" ? this.selected.length <= 0 : "#5A86FF";
    },
  },
};
</script>

<style scoped>
.cart-title {
  width: 438px;
  height: 23px;
  padding-top: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 23px;
  color: #7297ff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.v-data-table tr {
  background-color: #ffffff;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 7px;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  text-align: center !important;
}

/* tr.v-data-table__selected {
  background: #7d92f5 !important;
} */

.form-title {
  color: #7297ff !important ;
  width: 165px !important;
  height: 27px !important;

  /* font-family: "Open Sans" !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  /* line-height: 27px !important; */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.currency {
  padding-top: 30px !important;
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  /* line-height: 19px; */
  color: #979797;
}

.v-input__slot {
  align-items: center;
  display: flex;
  margin: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: height, min-height;
  width: 50%;
}
</style>
